<template>
  <div id="province-group">
    <el-container style="display:block;">
      <el-row>
        <el-col :span="24">
          <div class="cardTitle">分组列表</div>
          <el-button type="primary" @click="createGroup" class="cardTitleBtn">新建分组</el-button>
        </el-col>
        <el-col :span="24">
         <el-table :data="groupList" :default-sort = "{prop: 'account', order: 'desc'}" style="width: 100%">
          <el-table-column prop="name" label="组名" sortable></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="getDetail(scope.row)"
                type="primary"
                size="mini"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      </el-row>
    </el-container>
    <el-dialog
      title="分组"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form :rules="createRules" :model="currentGroup">
        <el-form-item label="分组名" prop="name">
          <el-input placeholder="分组名" v-model="currentGroup.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="doCreateGroup">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      editDialogVisible: false,
      createRules: {
        name: [{required: true, message: "组名不能为空", trigger: "blur"}],
      },
      updateRules: {
        name: [{required: true, message: "组名不能为空", trigger: "blur"}],
      },
      groupList: [],
      currentGroup: {
        guid: "",
        name: "",
        communityGuidList: [],
      }
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.init();
  },
  methods: {
    init() {
      let self = this;
      self.getGroupList();
      // self.groups = [];
    },
    clear() {
      this.currentGroup = {};
    },
    getGroupList() {
      let self = this;
      self.$axios.get("/community-group/list", {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        console.log("response.data.data", response.data.data);
        self.groupList = response.data.data;
      }).catch(function (error) {
        self.$message({
          message: "分组列表获取失败："+error.response.data.message,
          type: "warning",
        });
      });
    },
    createGroup() {
      let self = this;
      self.dialogVisible = true;
    },
    doCreateGroup() {
      let self = this;
      let data = self.currentGroup;
      self.$axios
        .post("/community-group/saveOrUpdate", JSON.stringify(data), {
          headers: {
            "content-type": "application/json",
            token: self.userInfo.token,
          },
        }).then(function(response) {
          self.$message({
            message: "操作成功",
            type: "success",
          });
          console.log("response.data.data 1 =", response.data.data);
          self.init();
          self.dialogVisible = false;
        })
        .catch(function (error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      console.log("row = ", row);
      self.$router.push({ name: "ProvinceGroupDetail", query: { guid: row.guid } });
      // self.$axios.get("/community-group/get/" + row.guid, {
      //   headers: { token: self.userInfo.token },
      // }).then(function(response) {
      //   console.log("response.data.data", response.data.data);
      //   // self.currentGroup = response.data.data;
      //   self.editDialogVisible = true;
      // }).catch(function (error) {
      //   self.$message({
      //     message: "分组详情获取失败："+error.response.data.message,
      //     type: "warning",
      //   });
      // });
    },
    doUpdateGroup() {

    }
  }
}
</script>
